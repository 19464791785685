.fx-container-l {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid #e8e8e8;
}
.fx-container-l .ant-menu-inline,
.fx-container-l .ant-menu-vertical,
.fx-container-l .ant-menu-vertical-left {
  border-right: 0;
}
.fx-container-l .header-logo {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e8e8;
}
.fx-container-l .header-logo img {
  width: auto;
  height: 46px;
  margin-left: 20px;
}
.fx-container-l .header-logo h3 {
  font-size: 22px;
  font-weight: bold;
  margin-left: 20px;
}
.fx-container-l .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.fx-container-l .ant-menu-item.ant-menu-item-only-child {
  padding: 0 !important;
}
.fx-container-l .ant-menu-item.ant-menu-item-only-child a {
  padding-left: 24px;
}
.fx-container-l .ant-menu-sub.ant-menu-inline > .ant-menu-item a {
  padding-left: 46px;
}
.fx-container-l .slide-menu {
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 64px);
}
.fx-container-l .slide-menu .active-route {
  color: #e93c50;
  display: block;
  padding-left: 46px;
  background-color: #fff0f0;
  position: relative;
}
.fx-container-l .slide-menu .active-route .active-route-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #e93c50;
}
.fx-container-l .slide-menu .route-link.ant-menu-item-selected {
  background: none;
  color: rgba(0,0,0,0.65);
}
.fx-container-l .slide-menu .route-link::after {
  border: none;
}
.fx-container-l .slide-menu .route-robot {
  padding-left: 24px !important;
}
.no-width {
  display: none;
}
/*# sourceMappingURL=src/layouts/slide/style.css.map */
@success-color: #52c41a;@primary-color: #E93C50;@link-color: #E93C50;