@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 9px
}

::-webkit-scrollbar:hover {
  background-color: #fafafa
}

::-webkit-scrollbar-track:hover {
  background: #f6f6f6
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 9px;
  background-clip: content-box;
  border: 5px solid transparent
}

::-webkit-scrollbar-thumb:hover {
  background: #c2c2c2;
  background-clip: content-box
}

::-webkit-scrollbar-thumb:vertical:active {
  background-color: #9da1a5;
  border: 5px solid transparent
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* common */

#root{
  width: 100%;
  height: 100%;
}
/* 常用宽度边距 */
.w60{width: 60px}.w80{width: 80px}.w100{width: 100px}.w120{width: 120px}.w140{width: 140px}.w160{width: 160px}
.ml5{margin-left: 5px}
.ml10{margin-left: 10px}
.ml15{margin-left: 15px}
.ml20{margin-left: 20px}
.mt5{margin-top: 5px}
.mt10{margin-top: 10px}
.mt15{margin-top: 15px}
.mt20{margin-top: 20px}

@success-color: #52c41a;@primary-color: #E93C50;@link-color: #E93C50;