.fx-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: #fff !important;
  border-bottom: 1px solid #e8e8e8;
}
.fx-header .user-info {
  cursor: pointer;
}
.no-height {
  display: none;
}
/*# sourceMappingURL=src/layouts/header/style.css.map */
@success-color: #52c41a;@primary-color: #E93C50;@link-color: #E93C50;