.wrapper {
  position: absolute;
  background: #f4f4f4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=src/layouts/style.css.map */
