.fx-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fx-main .fx-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.fx-main .fx-container .fx-container-r {
  flex: 1 1;
  height: 100%;
}
.fx-main .fx-container .fx-container-r .fx-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.fx-main .fx-container .fx-container-r .fx-content .fx-section {
  flex: auto;
  margin: 10px 10px 5px;
  padding: 15px;
  background: #fff;
  min-width: 1000px;
}
.fx-main .fx-container .fx-container-r .fx-footer {
  display: flex;
  justify-content: center;
  padding: 24px 50px 0;
}
/*# sourceMappingURL=src/layouts/V2Layout/style.css.map */
@success-color: #52c41a;@primary-color: #E93C50;@link-color: #E93C50;